<template>
  <v-main>
    <v-container :fluid="itemsToShow !== 5">
      <v-row>
        <v-col class="recommended-form" cols="12" xl="4" lg="5">
          <v-col md="12">
            <div class="enquiry-form-heading">
              <div class="map-icon">
                <v-icon class="mdi mdi-headset" size="25"></v-icon>
              </div>
              <span class="text-xl-h6 text-lg-h6 mt-1 font-weight-bold">Post a Property Ad to Sell or rent online</span>
            </div>
          </v-col>
          <v-card>
            <v-slide-x-transition>
              <v-col class="recommended-form" v-if="currentTemplate == 1">
                <v-col cols="12" class="py-0">
                  <span class="font-weight-bold text-subtitle-1">Location of the property</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-autocomplete outlined label="Select City" dense v-model="form.city"
                    :items="cities"></v-autocomplete>
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-bold text-subtitle-1">You Are</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-chip-group v-model="form.you_are" column active-class="primary--text">
                    <v-chip filter outlined value="Owner">
                      Owner
                    </v-chip>
                    <v-chip filter outlined value="Agent">
                      Agent
                    </v-chip>
                    <v-chip filter outlined value="Builder">
                      Builder
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" class="pt-1 pb-0">
                  <span class="font-weight-bold text-subtitle-1">Property Type</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-chip-group v-model="form.property_type" column active-class="primary--text">
                    <v-chip filter outlined value="Residential">
                      Residential
                    </v-chip>
                    <v-chip filter outlined value="Commercial">
                      Commercial
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" class="pt-1 pb-0">
                  <span class="font-weight-bold text-subtitle-1">Purpose</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-chip-group v-model="form.purpose" column active-class="primary--text">
                    <v-chip filter outlined value="Rent">
                      Rent
                    </v-chip>
                    <v-chip filter outlined value="Sell">
                      Sell
                    </v-chip>
                    <v-chip filter outlined value="PG/Co-living">
                      PG/Co-living
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" class="pt-4 pb-0">
                  <span class="font-weight-bold text-subtitle-1">What are you intereseted in?</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-chip-group v-model="form.size" column active-class="primary--text">
                    <v-chip filter outlined value="1 BHK">
                      1 BHK
                    </v-chip>
                    <v-chip filter outlined value="2 BHK">
                      2 BHK
                    </v-chip>
                    <v-chip filter outlined value="3 BHK">
                      3 BHK
                    </v-chip>
                    <v-chip filter outlined value="4 BHK">
                      4 BHK
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="form.price" placeholder="Pricing" outlined dense :error-messages="errors.price"
                    prepend-inner-icon="mdi-currency-rupee"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="form.name" placeholder="Name" outlined dense
                    :error-messages="errors.name"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="form.email" placeholder="Email - ID" outlined dense
                    :error-messages="errors.email"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="form.phone" placeholder="phone" outlined dense
                    :error-messages="errors.number"></v-text-field>
                </v-col>
              </v-col>
            </v-slide-x-transition>
            <v-slide-x-transition>
              <v-col v-if="currentTemplate == 2">
                <v-btn class="mx-2" fab x-small color="primary" @click="currentTemplate = 1">
                  <v-icon class="mr-2" color="black"> mdi-arrow-left </v-icon>
                </v-btn>
                <span> Previous </span>
                <v-col cols="12">
                  <label for="" class="font-weight-bold">Addrress</label>
                  <v-textarea v-model="form.address" outlined :error-messages="errors.address">
                  </v-textarea>
                </v-col>
                <v-col>
                  <label for="" class="font-weight-bold">Addrress</label> <br>
                  <span class="text-caption">(Add at least 5 photos)</span>
                  <file-pond name="mediaFiles" ref="formFile" accepted-file-types="image/jpeg, image/png"
                    allow-multiple="true" max-files="5" label-idle="Drop File Here" item-insert-location="before"
                    allow-file-metadata-setup v-bind:files="myFiles2">
                  </file-pond>
                </v-col>

              </v-col>
            </v-slide-x-transition>
          </v-card>
          <v-card-actions class="px-0">
            <v-btn :disabled="isNextEnabled == false && currentTemplate == 1"
              @click="currentTemplate == 1 ? currentTemplate = 2 : save()" color="#11d811"
              :dark="(isNextEnabled == true && currentTemplate == 1) || currentTemplate == 2" block>
              <span v-if="currentTemplate == 1"> Next</span>
              <span v-else>Upload Listing</span>
            </v-btn>
          </v-card-actions>
        </v-col>
        <v-col cols="12" xl="8" lg="7">
          <v-col md="12">
            <div class="enquiry-form-heading">
              <span class="text-h6 mt-1 font-weight-bold">FAQS</span>
            </div>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-col cols="12" xl="12" lg="12" class="my-1 rounded-lg" style="border: 1px solid orange;">
              <v-col cols="12" class="px-0 py-1">
                <span class="orange--text font-weight-bold">How to post a property ad on ProjectSqft</span>
              </v-col>
              Welcome to ProjectSqft, your trusted partner in the real estate market, specializing in properties
              across Mumbai and Navi Mumbai. At ProjectSqft, we believe in more than just selling properties; we
              believe in helping you find the perfect space that resonates with your dreams, aspirations, and
              lifestyle. Our deep-rooted presence in Mumbai and Navi Mumbai's real estate landscape allows us to
              offer
              you the best properties that these bustling cities have to offer.
            </v-col>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-col cols="12" xl="12" lg="12" class="my-1 rounded-lg" style="border: 1px solid orange;">
              <v-col cols="12" class="px-0 py-1">
                <span class="orange--text font-weight-bold">Our Mission</span>
              </v-col>
              Our mission is to transform the real estate experience into a seamless, transparent, and gratifying
              journey. We strive to connect buyers and sellers in a way that is beneficial to all parties
              involved.
              Whether you are a first-time homebuyer, an experienced investor, or looking to sell, ProjectSqft is
              committed to providing you with unparalleled service and expertise. Our aim is to make the process
              of
              buying or selling property straightforward and stress-free, ensuring that you find a space that
              truly
              feels like home.
            </v-col>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-col cols="12" xl="12" lg="12" class="my-1 rounded-lg" style="border: 1px solid orange;">
              <v-col cols="12" class="px-0 py-1">
                <span class="orange--text font-weight-bold">Why Choose
                  ProjectSqft?</span>
              </v-col>
              <ul style="padding-left: 20px;">
                <li class="mb-2">
                  <strong>Extensive Property Listings</strong>
                  <p>We offer a diverse range of properties in Mumbai and Navi Mumbai, from luxurious
                    apartments
                    to commercial spaces, catering to various preferences and budgets. Our listings are
                    regularly updated to bring you the latest and most exclusive properties on the market.
                  </p>
                </li>
                <li class="mb-2">
                  <strong>Expert Guidance</strong>
                  <p>Our team of experienced real estate professionals is dedicated to guiding you through
                    every
                    step of the property transaction. With in-depth market knowledge and a keen
                    understanding of
                    the local real estate trends, we provide you with insights that help you make informed
                    decisions.</p>
                </li>
                <li class="mb-2">
                  <strong>Transparency and Integrity</strong>
                  <p>At ProjectSqft, we prioritize transparency and integrity in all our dealings. We believe
                    in
                    building trust with our clients through honest communication and ethical practices. Our
                    goal
                    is to ensure that you have all the information you need to make a confident and informed
                    choice.</p>
                </li>
                <li class="mb-2">
                  <strong>Personalized Service</strong>
                  <p>We understand that every client is unique, with their own set of requirements and
                    preferences. Our approach is tailored to meet your specific needs, whether you're
                    searching
                    for a family home, an investment property, or a commercial space for your business. We
                    listen, understand, and work diligently to match you with properties that align with
                    your
                    vision.</p>
                </li>
              </ul>
            </v-col>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-col cols="12" xl="12" lg="12" class="my-1 rounded-lg" style="border: 1px solid orange;">
              <v-col cols="12" class="px-0 py-1">
                <span class="orange--text font-weight-bold">The ProjectSqft Advantage
                </span>
              </v-col>
              Mumbai and Navi Mumbai are dynamic, ever-growing cities with a vibrant real estate market.
              Navigating
              this market requires not just expertise but a keen eye for detail and an understanding of what makes
              each locality unique. At ProjectSqft, we leverage our local expertise to bring you properties that
              are
              not only prime investments but also spaces where you can build a future.
              <br>
              <br>
              We take pride in our extensive network of developers, property owners, and agents, which enables us
              to
              provide you with a wide array of choices. From the upscale neighborhoods of South Mumbai to the
              rapidly
              developing sectors of Navi Mumbai, our portfolio includes properties that cater to all tastes and
              budgets.
            </v-col>
          </v-col>
        </v-col>
      </v-row>
      <v-dialog v-model="SuccessDialog" width="500" persistent>
        <v-card>
          <v-card-title>
            Property Added successfully
          </v-card-title>
          <v-card-text>
            <v-col cols="12" class="d-flex justify-center">
              <img src="../../assets/success.gif" width="350" alt="">
            </v-col>
            <span>
              Property Posted successfully customer will direclty can contact you
            </span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$router.push('/projects')">
              ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"; // Import necessary CSS
import "filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginMediaPreview from "filepond-plugin-media-preview";
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);
export default {
  name: "EnquiryCreatePage",
  components: {
    FilePond,
  },
  data() {
    return {
      valid: false,
      form: {
        you_are: null,
        property_type: null,
        purpose: null,
        size: null,
        price: null,
        name: null,
        email: null,
        phone: null,
      },
      isLoading: false,
      isNextEnabled: false,
      SuccessDialog: false,
      currentTemplate: 1,
      cities: [],
    };
  },
  computed: {
    itemsToShow() {
      if (window.innerWidth >= 1600) {
        return 5;
      } else if (window.innerWidth < 720) {
        return 1;
      } else if (window.innerWidth < 1080) {
        return 3;
      } else {
        return 4;
      }
    },
  },
  watch: {
    form: {
      handler() {
        if (this.form.city != null &&
          this.form.you_are != null &&
          this.form.property_type != null &&
          this.form.purpose != null &&
          this.form.size != null &&
          this.form.price != null &&
          this.form.name != null &&
          this.form.email != null &&
          this.form.phone != null
        ) {
          this.isNextEnabled = true;  // Enable the next step
          console.log(this.isNextEnabled);
        } else {
          this.isNextEnabled = false;  // Disable if any condition is false
          console.log(this.isNextEnabled);
        }
      },
      deep: true,  // Watch deeply for changes in form's nested properties
      immediate: true,  // Execute the watcher immediately on component mount
    }
  },
  mounted() {
    this.fetchCities();
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        let response = await axios.post("/property_ads", this.form);
        this.form = response.data.data;
        this.saveFormDetails();
        // await this.saveUserTimestamp(null, this.form);
        this.isLoading = false;
        this.SuccessDialog = true;
        // this.$router.back()
      } catch (e) {
        console.log('error', e.response.data.errors);
        const errors = e.response.data.errors;
        if (errors.email || errors.size || errors.price || errors.name || errors.phone) {
          this.currentTemplate = 1;
        }
        this.isLoading = false;
      }
    },
    async saveFormDetails() {
      const fileItems = this.$refs.formFile.getFiles(); // Get all files from FilePond
      console.log(fileItems);

      let formData = new FormData();
      formData.append("formId", this.form.id);

      // Loop through each file and append it to the formData
      fileItems.forEach((fileItem, index) => {
        formData.append(`image_path_${index + 1}`, fileItem.file); // Append each file as 'form_file[]'
      });

      try {
        const response = await axios.post('upload_project_ad_images', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("Bulk upload successful:", response);
      } catch (error) {
        console.error("Error during bulk upload:", error);
      }
    },
    async fetchCities() {
      const query = `
      [out:json][timeout:25];
      area["ISO3166-1"="IN"][admin_level=2];
      node["place"="city"](area);
      out body;
      `;
      try {
        const response = await axios.post('https://overpass-api.de/api/interpreter', query, {
          headers: {
            'Content-Type': 'text/plain'
          }
        });
        this.cities = response.data.elements.map(city => ({
          id: city.id,
          text: city.tags.name
        }));
        console.log('cities', this.cities);

      } catch (error) {
        console.error("Error fetching cities: ", error);
      }
    },
    async saveUserTimestamp(userId, formData) {
      console.log("User timestamp saved:", userId, formData);
    },
  },
};
</script>

<style scoped>
.enquire-form-container {
  background-color: #fff3e0;
  padding: 20px;
  border-radius: 10px;
}

.selectable-option {
  padding: 12px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
  /* Transparent by default */
  color: black;
}

.selected-owner {
  background-color: rgb(248, 180, 53);
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-builder {
  background-color: orange;
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-agent {
  background-color: orange;
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-option {
  background-color: transparent;
  /* Transparent background for Agent and Builder */
  color: black;
  /* Text color */
}


.selected-new-property {
  background-color: rgb(236,
      172,
      53);
  /* Primary color background for New Property */
  color: white;
  /* White text for contrast */
}

.selected-old-property {
  background-color: rgb(236,
      172,
      53);
  /* Transparent background for Old Property */
  color: black;
  /* Text color */
}


.selected-flat {
  background-color: rgb(241, 184, 78);
  /* Primary color background for selected flat */
  color: white;
  /* White text for contrast */
}
</style>
