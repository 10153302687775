import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import axios from "axios";

Vue.use(VueRouter);

// Layout
import UnAuthLayout from "../views/layouts/unauth-layout.vue";
import AuthLayout from "../views/layouts/auth-layout.vue";

// Static
import HomePage from "../views/home";

// Auth
import LoginPage from "../views/auth/login.vue";
import ForgotPasswordPage from "../views/auth/forgot-password.vue";
// homepage
import ProjectsPage from "../views/projects/index.vue";
import ProjectEditPage from "../views/projects/id.vue";

//Enquire Form
import EnquirePage from "../views/enquiry-forms/create.vue";
import PropertyAdPage from "../views/properties-ad/create.vue";

//About pAges
import AboutUsPage from "../views/policies-pages/about.vue";
import PrivacyPage from "../views/policies-pages/privacy.vue";
import RefundPage from "../views/policies-pages/refund.vue";
import TermsAndConidtionsPage from "../views/policies-pages/terms_condition.vue";

const routes = [
  // AuthLayout
  {
    path: "/auth",
    name: "AuthLayout",
    component: AuthLayout,
    children: [
      {
        path: "/home",
        name: "HomePage",
        component: HomePage,
      },
    ],
  },
  // End AuthLayout

  // UnAuthLayout
  {
    path: "/unauth",
    name: "UnAuthLayout",
    component: UnAuthLayout,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/projects",
        name: "ProjectsPage",
        component: ProjectsPage,
      },
      {
        path: "/projects/:projectId",
        name: "ProjectEditPage",
        component: ProjectEditPage,
      },
      {
        path: "/enquire-form",
        name: "EnquirePage",
        component: EnquirePage,
      },
      {
        path: "/properties-ad",
        name: "PropertyAdPage",
        component: PropertyAdPage,
      },
      {
        path: "/about-us",
        name: "AboutUsPage",
        component: AboutUsPage,
      },
      {
        path: "/privacy-policies",
        name: "PrivacyPage",
        component: PrivacyPage,
      },
      {
        path: "/refund-policies",
        name: "RefundPage",
        component: RefundPage,
      },
      {
        path: "/terms-and-conditions",
        name: "TermsAndConidtionsPage",
        component: TermsAndConidtionsPage,
      },
    ],

  },
  //End UnAuthLayout
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to) {
  //   if (to.hash) {
  //     return {
  //       selector: to.hash,
  //       behavior: 'smooth'
  //     };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
});

router.beforeEach((to, from, next) => {
  if (to.name === null) {
    return next({
      name: "ProjectsPage",
    });
  }
  next();
});

router.afterEach(async (to, from) => {
  if (
    store.getters["auth/authenticated"] &&
    from.name &&
    !to.name.includes("Create") &&
    !to.name.includes("Edit")
  ) {
    let endTime = new Date();
    let startTime = from.params.startTime;
    let timeSpentInSeconds = "0";
    if (startTime) {
      timeSpentInSeconds = (endTime - startTime) / 1000;
    }
    if (timeSpentInSeconds > 1200) {
      timeSpentInSeconds = 1200;
    }
    console.log(
      `Time spent on "${to.name}" route: ${timeSpentInSeconds} seconds`
    );
    let form = {
      from_path: from.path,
      from_name: from.name,
      user_id: store.getters["auth/user"].id,
      timespent: timeSpentInSeconds,
    };
    await axios.post(`/user_timestamps`, form);
  }
});

export default router;
