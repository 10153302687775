<template>
  <v-main>
    <v-container :fluid="itemsToShow2 !== 5">
      <v-row>
        <v-col cols="12">
          <v-card class="justify-center d-flex py-4 rounded-lg">
            <v-col xl="8" lg="12" class="image-section">
              <v-row>
                <v-col class="py-0" cols="7" xl="9" lg="9">
                  <h3>
                    {{ form.name }}
                  </h3>
                  <h5 v-if="itemsToShow == 2">
                    By {{ form.builder.name || "" }}
                    <span class="text-subtitle-1" style="font-weight: 400">
                      {{ form.address }}</span>
                  </h5>
                  <h5 v-else>By {{ form.builder.name || "" }}</h5>
                </v-col>
                <v-col class="py-0" cols="5" xl="3" lg="3">
                  <h3>{{ form.starting_price }}* - {{ form.ending_price }}*</h3>
                  <span class="text-caption pl-6"> 1 & 2 and 4BHK </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row v-if="itemsToShow == 2 || itemsToShow == 4">
                    <v-col cols="3" class="mr-9" style="position: relative">
                      <img :src="form.feature_imagepath != null
                        ? mediaUrl + form.feature_imagepath
                        : require('../../assets/construction.jpg')
                        " width="320" height="220" class="rounded-lg" />
                      <v-row v-if="
                        this.form.launch_date != null &&
                        this.form.end_date != null
                      " class="white rounded-lg" style="position: absolute; top: 30px; left: 30px">
                        <v-col cols="3">
                          <div style="
                              height: 25px;
                              width: 25px;
                              background: orange;
                            " class="rounded-circle align-center d-flex">
                            <v-icon size="20" class="mdi mdi-clock-outline ml-1"></v-icon>
                          </div>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-1 pr-0 mt-2" style="line-height: 1">
                          <h4 class="font-weight-bold" style="font-size: 15px">
                            {{ remainingDays }} Days
                          </h4>
                          <span class="text-caption"> Remaining </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="2" class="mr-6">
                      <img :src="form.imagepath_1 != null
                        ? mediaUrl + form.imagepath_1
                        : require('../../assets/construction.jpg')
                        " width="210" height="220" class="rounded-lg" />
                    </v-col>
                    <v-col cols="2" class="mr-8">
                      <img contain :src="form.imagepath_1 != null
                        ? mediaUrl + form.imagepath_1
                        : require('../../assets/construction.jpg')
                        " width="220" height="106" class="rounded-lg" />
                      <img contain :src="form.imagepath_2 != null
                        ? mediaUrl + form.imagepath_2
                        : require('../../assets/construction.jpg')
                        " width="220" height="106" class="rounded-lg" />
                    </v-col>
                    <v-col cols="2">
                      <img contain :src="form.imagepath_3 != null
                        ? mediaUrl + form.imagepath_3
                        : require('../../assets/construction.jpg')
                        " width="220" height="106" class="rounded-lg" />
                      <img contain :src="form.imagepath_4 != null
                        ? mediaUrl + form.imagepath_4
                        : require('../../assets/construction.jpg')
                        " width="220" height="106" class="rounded-lg" />
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" xl="6" lg="6">
                      <carousel :perPage="itemsToShow" :paginationEnabled="false">
                        <vue-carousel-slide v-for="(project, index) in PhoneViewProjectImages" :key="index">
                          <v-col cols="12" class="py-0 pl-0">
                            <v-img height="320" class="mr-9 rounded-lg" style="width: 100%"
                              :src="mediaUrl + project[`image${index}`]" />
                          </v-col>
                        </vue-carousel-slide>
                      </carousel>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-3 pb-7">
                  <v-row class="d-flex justify-center" :class="itemsToShow == 2 ? 'mr-6' : 'mr-0'">
                    <v-col cols="12" xl="5" lg="5" class="pt-0">
                      <div class="horizontal timeline">
                        <div class="steps">
                          <div v-for="(item, i) in items" :key="i" class="step" :class="item.class">
                            <span>{{ item.title }}</span>
                          </div>
                        </div>
                        <div class="line" :style="lineStyle"></div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
          <v-col cols="12" class="px-0 pb-0">
            <!-- to="/enquire-form" -->
            <v-btn dark v-if="isSubmitted" block color="#ff980099" :loading="isLoading">
              Submitted
            </v-btn>
            <v-btn v-else block dark color="orange" @click="showFormDialog">Enquire Now</v-btn>
          </v-col>
          <div>
            <v-col lg="12">
              <v-row>
                <v-col cols="12" xl="6" lg="6" class="px-0">
                  <v-card height="500" class="rounded-lg">
                    <v-card-title class="font-weight-bold">
                      <span style="font-size: 18px" class="d-flex align-center">
                        <div style="height: 30px; width: 30px; background: orange"
                          class="rounded-lg align-center d-flex justify-center mr-2">
                          <v-icon size="18" class="mdi mdi-apps pr-2"></v-icon>
                        </div>
                        Floor Plan And Configurations
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-col cols="12" class="py-0 px-0">
                        <v-chip-group v-model="sizes" column active-class="primary--text"
                          @change="filterFloorPlansByBHK">
                          <v-chip filter outlined value="1BHK">1 BHK</v-chip>
                          <v-chip filter outlined value="2BHK">2 BHK</v-chip>
                          <v-chip filter outlined value="3BHK">3 BHK</v-chip>
                          <v-chip filter outlined value="4BHK">4 BHK</v-chip>
                        </v-chip-group>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col v-if="filteredFloorPlans.length > 0" cols="12" class="py-1 px-0">
                        <span class="text-subtitle-2 black--text font-weight-bold">
                          {{
                            filteredFloorPlans[0].project_set_floorplans.length
                          }}
                          Sizes Available
                        </span>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col class="floorplan-class" cols="12" xl="6" lg="11" v-if="filteredFloorPlans.length > 0">
                        <carousel :perPage="itemsToShow" :paginationEnabled="false"
                          :navigationEnabled="itemsToShow == 1 ? false : true">
                          <vue-carousel-slide v-for="(
                              floorplan, floorplanIndex
                            ) in filteredFloorPlans[0].project_set_floorplans" :key="floorplanIndex">
                            <v-col cols="12" class="pt-3 pb-0">
                              <span class="text-subtitle-2 black--text font-weight-bold">
                                {{ floorplan.name }}
                              </span>
                            </v-col>
                            <v-col cols="12" class="py-0 pl-0" align="start">
                              <v-img height="200" width="200" class="mr-9 floorspan-image" contain
                                :src="mediaUrl + floorplan.floorplan_imagepath" />
                            </v-col>
                            <v-row>
                              <v-col cols="5">
                                <span class="text-subtitle-2 black--text font-weight-bold">{{ floorplan.pricing
                                  }}</span>
                              </v-col>
                              <v-col cols="6">
                                <span class="text-subtitle-2 black--text font-weight-bold">{{ floorplan.size }}</span>
                              </v-col>
                            </v-row>
                          </vue-carousel-slide>
                        </carousel>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" xl="6" lg="6" :class="itemsToShow == 1 ? 'px-0 py-0' : ''">
                  <v-card :style="itemsToShow == 1
                    ? { minHeight: '500px' }
                    : { height: '500px' }
                    " class="overflow-y-auto rounded-lg">
                    <v-expansion-panels v-model="panel" multiple>
                      <v-expansion-panel>
                        <v-expansion-panel-header class="font-weight-bold py-1 text-h6">
                          Project Specification
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="justify-space-around project-specification">
                            <v-col cols="6" xl="5" lg="5" v-for="n in 4" :key="n" class="rounded-lg mt-5"
                              style="background: #fee2b5; height: 130px">
                              <v-col col="12" class="py-0 px-0">
                                <span>
                                  <v-icon color="orange" size="32">mdi-wall-sconce</v-icon>
                                </span>
                                <span class="text-subtitle-2 text-xl-subtitle-1 text-lg-subtitle-1 font-weight-bold">
                                  Electric Fittings
                                </span>
                              </v-col>
                              <v-col cols="12" class="py-0 px-0">
                                <span class="text-body-2">
                                  Legrand Switch Boards and siwtches
                                </span>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel v-model="panel" v-if="form.project_amenities.length != 0">
                        <v-expansion-panel-header class="font-weight-bold text-h6">
                          Amenities
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="amenities-section amenities-class">
                            <v-col cols="4" xl="3" lg="3" md="3" class="rounded-lg px-0 my-1"
                              style="background: #fee2b5" v-for="n in form.project_amenities" :key="n">
                              <v-col col="12" class="py-0 px-0" align="center">
                                <span>
                                  <v-icon color="black" size="40">{{
                                    n.amenitie != null ? n.amenitie.icon : ""
                                  }}</v-icon>
                                </span>
                              </v-col>
                              <v-col class="py-0 px-0" align="center">
                                <span class="text-caption text-xl-body-2 text-lg-body-2">
                                  {{
                                    n.amenitie != null ? n.amenitie.name : ""
                                  }}
                                </span>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel v-model="panel" v-if="form.project_locations.length != 0">
                        <v-expansion-panel-header class="font-weight-bold text-h6">
                          Location Benefits
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row class="amenities-section amenities-class">
                            <v-col cols="4" xl="3" lg="3" md="3" class="rounded-lg px-0 my-1"
                              style="background: #fee2b5" v-for="n in form.project_locations" :key="n">
                              <v-col col="12" class="py-0 px-0" align="center">
                                <span>
                                  <v-icon color="black" size="45">{{
                                    n.location != null ? n.location.icon : ""
                                  }}</v-icon>
                                </span>
                              </v-col>
                              <v-col class="py-0 px-0" align="center">
                                <span class="text-caption text-xl-body-2 text-lg-body-2">
                                  {{
                                    n.location != null ? n.location.name : ""
                                  }}
                                </span>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>
                </v-col>
                <v-col cols="12" xl="6" lg="6" class="pt-6 px-0">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-card class="rounded-lg">
                        <v-card-title>
                          <div class="map-icon mr-4">
                            <v-icon size="25" class="mdi mdi-account"></v-icon>
                          </div>
                          <span> Anonymous </span>
                        </v-card-title>
                        <v-card-text>
                          <v-col cols="12" class="px-0 py-0">
                            <v-textarea v-model="review.comment" outlined :error-messages="errors.comment" rows="2">
                            </v-textarea>
                          </v-col>
                          <v-col cols="12" class="px-0 py-0">
                            <v-btn color="orange" dark @click="submitReview" :loading="isReviewLoading"
                              block>submit</v-btn>
                          </v-col>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" class="pt-2">
                      <v-card max-height="400" class="rounded-lg overflow-y-auto" v-if="form.reviews.length != 0">
                        <div v-for="(comment, i) in form.reviews" :key="i">
                          <v-card-title>
                            <div class="map-icon mr-4">
                              <v-icon size="25" class="mdi mdi-account"></v-icon>
                            </div>
                            <span style="line-height: 1">
                              {{ comment.name }} <br />
                              <span class="text-caption">
                                {{ formatDate(comment.created_at) }}
                              </span>
                            </span>
                          </v-card-title>
                          <v-card-text>
                            <v-col cols="12" style="border: 1px solid black" class="rounded-lg">
                              {{ comment.comment }}
                            </v-col>
                          </v-card-text>
                        </div>
                      </v-card>
                      <v-card class="rounded-lg" v-else>
                        <v-card-text class="text-center">
                          No Review Yet!
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xl="6" lg="6" :class="itemsToShow == 1 ? 'px-0 py-0' : ''">
                  <v-card elevation="4" class="rounded-lg">
                    <v-col cols="12" class="pb-0">
                      <span class="text-h6 font-weight-bold ml-2">
                        Other Projects
                      </span>
                    </v-col>
                    <v-card-text class="pb-0">
                      <v-col cols="12" class="home-page py-0 px-0">
                        <carousel :perPage="itemsToShow2" :navigationEnabled="itemsToShow2 == 1 ? false : true"
                          :paginationEnabled="false">
                          <vue-carousel-slide v-for="(n, index) in otherProjects" :key="index">
                            <v-col class="px-0 pb-1" cols="3" xl="4" lg="4">
                              <a :href="`/projects/${encryptIt(n.id)}`">
                                <div class="category-list">
                                  <img class="category-img" :src="mediaUrl + n.feature_imagepath" :width="$vuetify.breakpoint.smAndDown
                                    ? 200
                                    : $vuetify.breakpoint.lgAndDown
                                      ? 185
                                      : 270
                                    " height="245" />
                                  <div class="overlay-title">
                                    <v-row class="align-center">
                                      <v-col cols="12" class="py-0">
                                        <h3>{{ n.name || "" }}</h3>
                                        <h5>
                                          By
                                          {{
                                            n.builder != null
                                              ? n.builder.name
                                              : "" || ""
                                          }}
                                        </h5>
                                      </v-col>
                                      <v-col cols="12" xl="12" lg="12" md="12" class="py-0 px-0">
                                        <span class="ml-2 text-caption">
                                          <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                          {{ n.address }}
                                        </span>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </div>
                              </a>
                            </v-col>
                          </vue-carousel-slide>
                        </carousel>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="EnquireFormDialog" max-width="600">
        <v-card>
          <v-col class="recommended-form">
            <v-row>
              <v-col md="12">
                <div class="enquiry-form-heading">
                  <div class="map-icon">
                    <v-icon class="mdi mdi-headset" size="25"></v-icon>
                  </div>
                  <span class="text-h6 mt-1 font-weight-bold">Enquiry Form</span>
                </div>
              </v-col>
            </v-row>
            <v-card class="mt-2">
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold text-subtitle-1">Please Enter Your Details Here</span>
              </v-col>
              <v-col cols="12" class="pt-1 pb-0">
                <span class="font-weight-bold text-subtitle-1">What are you intereseted in?</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-chip-group v-model="enquireform.bhk_size" column active-class="primary--text">
                  <v-chip filter outlined value="1 BHK"> 1 BHK </v-chip>
                  <v-chip filter outlined value="2 BHK"> 2 BHK </v-chip>
                  <v-chip filter outlined value="3 BHK"> 3 BHK </v-chip>
                  <v-chip filter outlined value="4 BHK"> 4 BHK </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" class="pt-1 pb-0">
                <span class="font-weight-bold text-subtitle-1">Available At?</span>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-chip-group v-model="enquireform.available_at" column active-class="primary--text">
                  <v-chip filter outlined value="Morning"> Morning </v-chip>
                  <v-chip filter outlined value="Afternoon"> Afternoon </v-chip>
                  <v-chip filter outlined value="Evening"> Evening </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field v-model="enquireform.name" placeholder="Name" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field v-model="enquireform.email" placeholder="Email - ID" outlined dense></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field v-model="enquireform.number" placeholder="phone" outlined dense></v-text-field>
              </v-col>
            </v-card>
            <v-card-actions>
              <v-btn style="background-color: green; color: white; width: 100%" @click="save_enquire_form"
                :loading="isLoading">
                Enquire
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
import axios from "axios";
import { Carousel, Slide as VueCarouselSlide } from "vue-carousel";

export default {
  name: "projectEditPage",
  data() {
    return {
      menu: false,
      EnquireFormDialog: false,
      form: {},
      panel: [0, 1, 2],
      enquireform: {
        bhk_size: "1 BHK",
        available_at: "Morning",
      },
      sizes: "1BHK",
      formsizes: "1BHK",
      items: [],
      setItems: [{ text: "value_list", value: 0 }],
      builderItems: [],
      otherProjects: [],
      regionItems: [],
      PhoneViewProjectImages: [],
      project: {},
      ProjectFloorPlans: [],
      remainingDays: "",
      review: {},
      isLoading: false,
      isReviewLoading: false,
      isSubmitted: false,
    };
  },
  computed: {
    itemsToShow() {
      if (window.innerWidth >= 1600) {
        return 2;
      } else if (window.innerWidth < 720) {
        return 1;
      } else if (window.innerWidth < 1080) {
        return 2;
      } else {
        return 4;
      }
    },
    itemsToShow2() {
      if (window.innerWidth >= 1600) {
        return 3;
      } else if (window.innerWidth < 720) {
        return 1;
      } else if (window.innerWidth < 1080) {
        return 3;
      } else {
        return 3;
      }
    },
    filteredFloorPlans() {
      const filtered = this.ProjectFloorPlans.filter((projectSet) => {
        return projectSet.set.description
          .toLowerCase()
          .includes(this.sizes.toLowerCase());
      });
      console.log("Filtered Floor Plans:", filtered);
      return filtered;
    },
    isFluid() {
      return window.innerWidth < 1264;
    },
    lineStyle() {
      let stepsCompleted = null;
      if (this.form.status == 1) {
        stepsCompleted = [
          true, // 'Commenced' step is always completed
          this.form.is_phase_1_completed,
          this.form.is_phase_2_completed,
          this.form.is_phase_3_completed,
        ].filter(Boolean).length;
      }
      if (this.form.status == 2) {
        stepsCompleted = [
          true, // 'Commenced' step is always completed
          this.form.is_phase_3_completed,
          this.form.is_oc_recieved,
          this.form.is_cc_recieved,
        ].filter(Boolean).length;
      }
      console.log("stepsCompleted", stepsCompleted);

      const percentage = (stepsCompleted / 4) * 100;
      console.log("percentage", percentage);
      return {
        background: `linear-gradient(to right, #21e50f 0%, #21e50f ${percentage}%, #81e686 ${percentage}%, #f2f2f2 100%)`,
      };
    },
  },
  components: {
    Carousel,
    VueCarouselSlide,
  },
  watch: {
    EnquireFormDialog(newVal) {
      if (newVal == false) {
        const steps = document.querySelectorAll(
          ".horizontal.timeline .steps .step"
        );
        steps.forEach((step) => {
          step.style.zIndex = 500;
        });
      }
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    showFormDialog() {
      if (this.itemsToShow == 1) {
        this.$router.push(`/enquire-form?project_id=${this.form.id}`);
      } else {
        this.EnquireFormDialog = true;
        const steps = document.querySelectorAll(
          ".horizontal.timeline .steps .step"
        );
        steps.forEach((step) => {
          step.style.zIndex = 0;
        });
        this.isSubmitted =
          localStorage.getItem(`'project_submitted_${this.form.id}'`) ==
            this.form.id
            ? true
            : false;
      }
    },
    filterFloorPlansByBHK(bhkType) {
      this.sizes = bhkType;
    },
    async getData() {
      this.isLoading = true;
      let encryptedId = this.$route.params.projectId;
      await axios
        .get(`/projects/${encryptedId}`)
        .then((response) => {
          this.form = response.data.data;
          this.isSubmitted =
            localStorage.getItem(`'project_submitted_${this.form.id}'`) ==
              this.form.id
              ? true
              : false;
          this.ProjectFloorPlans = this.form.project_sets;
          this.filterFloorPlansByBHK("1BHK");
          if (this.itemsToShow == 1) {
            this.PhoneViewProjectImages = [
              {
                image0: this.form.feature_imagepath,
              },
              {
                image1: this.form.imagepath_1,
              },
              {
                image2: this.form.imagepath_2,
              },
              {
                image3: this.form.imagepath_3,
              },
              {
                image4: this.form.imagepath_4,
              },
            ];
          }
          if (this.form.status == 2) {
            this.items = [
              { title: "Commenced", class: "completed" },
              {
                title: "Phase 3",
                class: this.form.is_phase_3_completed
                  ? "completed"
                  : this.form.is_phase_2_completed
                    ? "current"
                    : "",
              },
              {
                title: "OC Received",
                class: this.form.is_oc_received
                  ? "completed"
                  : this.form.is_phase_3_completed
                    ? "current"
                    : "",
              },
              {
                title: "CC Received",
                class: this.form.is_cc_received
                  ? "completed"
                  : this.form.is_oc_received
                    ? "current"
                    : "",
              },
            ];
          }
          if (this.form.status == 1) {
            this.items = [
              { title: "Commenced", class: "completed" },
              {
                title: "Phase 1",
                class: this.form.is_phase_1_completed ? "completed" : "current",
              },
              {
                title: "Phase 2",
                class: this.form.is_phase_2_completed
                  ? "completed"
                  : this.form.is_phase_1_completed
                    ? "current"
                    : "",
              },
              {
                title: "Phase 3",
                class: this.form.is_phase_3_completed
                  ? "completed"
                  : this.form.is_phase_2_completed
                    ? "current"
                    : "",
              },
            ];
          }
          if (this.form.launch_date != null && this.form.end_date != null) {
            let launchDate = new Date(this.form.launch_date);
            let endDate = new Date(this.form.end_date);
            let differenceInTime = endDate - launchDate;
            this.remainingDays = Math.ceil(
              differenceInTime / (1000 * 3600 * 24)
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.isLoading = false;
        });
      try {
        let response2 = await axios.get(`/projects`, {
          headers: {
            "company-id": 1,
          },
        });
        this.otherProjects = response2.data.data;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async save_enquire_form() {
      if (this.isSubmitted) return;
      try {
        this.isLoading = true;
        this.enquireform.project_id = this.form.id;
        await axios.post(`/enquire_forms`, this.enquireform);
        localStorage.setItem(
          `'project_submitted_${this.form.id}'`,
          this.form.id
        );
        this.isLoading = false;
        this.EnquireFormDialog = false;
        this.getData();
      } catch (e) {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return new Date(date).toLocaleDateString("en-GB", options);
    },
    async submitReview() {
      this.isReviewLoading = true;
      try {
        this.isReviewLoading = true;
        this.review.project_id = this.form.id;
        this.review.name = "anonymous";
        await axios.post(`/reviews`, this.review);
        this.isReviewLoading = false;
        this.getData();
      } catch (e) {
        this.isReviewLoading = false;
      }
    },
    async saveUserTimestamp() {
      try {
        let form = {
          from_path: `/project/${this.form.id}`,
          from_name: "projectEditPage",
          user_id: this.user.id,
          old_json: JSON.stringify(this.old_json),
          new_json: JSON.stringify(this.form),
        };
        await axios.post(`/user_timestamps`, form);
        this.$router.push("/projects");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
/* h3,
h5,
span {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

h3,
h5,
span {
  opacity: 1;
} */
.project-specification .col-6 {
  max-width: 49%;
}

.feature-developers .developers-image-div {
  padding-right: 241px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 64px;
  background: #fff6e7;
}

.region-class .hooper {
  height: 330px;
}

.v-application ul,
.v-application ol {
  padding-left: 8px !important;
}

.hooper .hooper-slide {
  width: 251px !important;
}

.recommended-form .VueCarousel .VueCarousel-slide {
  padding: 2px;
}

.home-page .hooper .col-xl-3 {
  flex: 0 0 21.6% !important;
}

.category-list,
.category-details {
  position: relative;
}

.category-list {
  position: relative;
  display: inline-block;
}

.category-list:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
  background: linear-gradient(to bottom,
      rgb(0 0 0 / 0%) 71%,
      rgb(0 0 0 / 77%) 83%);
}

.category-img {
  display: block;
  border-radius: 10px;
}

.overlay-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
  z-index: 999;
  color: white;
  width: 100%;
  line-height: 1.4;
}

.overlay-title h3 {
  font-size: 15px;
  font-weight: 700;
}

.overlay-title h5 {
  font-size: 12px;
  font-weight: 300;
}

.map-marker-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  height: 22px;
  width: 22px;
  padding: 5px;
}

.percent-icon-class {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  height: 22px;
  width: 22px;
  padding: 5px;
}

.amenities-section .col-3 {
  max-width: 24% !important;
}

.horizontal.timeline {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.horizontal.timeline::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 0.2em;
  background-color: #f2f2f2;
}

.horizontal.timeline .line {
  display: block;
  position: absolute;
  width: 100%;
  /* Set to 100% initially */
  height: 5px;
  /* background: linear-gradient(to right, #388e3c 0%, #388e3c 50%, #81e686 50%, #f2f2f2 100%); */
  transition: width 0.4s ease-in-out;
}

.horizontal.timeline .steps {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.horizontal.timeline .steps .step {
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
  padding: 8px;
  margin: 0 2em;
  box-sizing: content-box;
  color: white;
  /* Default color for pending steps */
  background-color: currentColor;
  border: 0.25em solid white;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  z-index: 500;
}

.horizontal.timeline .steps .step.completed {
  color: #388e3c;
  /* Dark green for completed steps */
}

.horizontal.timeline .steps .step.current {
  color: #71cb35;
  /* Lighter green for current step */
}

.horizontal.timeline .steps .step:first-child {
  margin-left: 0;
}

.horizontal.timeline .steps .step:last-child {
  margin-right: 0;
}

.horizontal.timeline .steps .step span {
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #000;
}

.horizontal.timeline .steps .step.current::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation-name: animation-timeline-current;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.horizontal.timeline .steps .step.current span {
  opacity: 0.8;
}

@keyframes animation-timeline-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
</style>
<style>
.floorspan-image .v-image__image {
  background-position: left !important;
}

.v-expansion-panel-header__icon .mdi-chevron-down {
  background: orange;
  font-weight: bold;
  border-radius: 5px;
}
</style>
