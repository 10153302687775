<template>
  <div>
    <!-- v-overlay with a loader -->
    <v-overlay v-if="isLoading" opacity="0.5" color="#E3F2FD">
      <img src="../src/assets/spinner.gif" alt="">
    </v-overlay>

    <!-- Router view -->
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/layouts/footer';
const CryptoJS = require("crypto-js");
export default {
  components: {
    Footer,
  },
  data() {
    return {
      isLoading: true, // Loading state
    };
  },
  mounted() {
    // Check if the document is fully loaded
    if (document.readyState === "complete") {
      this.hideLoader(); // Page is fully loaded, hide the loader
    } else {
      // Listen for the load event if the document is not ready
      window.addEventListener('load', this.hideLoader);
    }
  },
  beforeDestroy() {
    // Clean up the event listener
    window.removeEventListener('load', this.hideLoader);
  },
  methods: {
    // Show loader
    showLoader() {
      this.isLoading = true;
    },

    // Hide loader
    hideLoader() {
      this.isLoading = false;
    },
    logout() {
      this.logOut();
      location.replace("/");
    },
    encryptIt(string) {
      let encrypted = CryptoJS.AES.encrypt(
        `${string}`,
        "secret key"
      ).toString();
      return encrypted;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap");

#app {
  background: #fff6e7;
}

.sr_no {
  border-top-left-radius: 4px !important;
}

.action_th {
  min-width: 115px;
}

.striped-table .table-row-even {
  background-color: #f5f5f5;
}

.striped-table .table-row-odd {
  background-color: #ffffff;
}

.striped-table .table-row-last {
  border-end-end-radius: 4px !important;
  /* border-radius-bottomleft: 4px !important; */
}
</style>
