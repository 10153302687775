<template>
  <v-container :fluid="itemsToShow !== 5">
    <v-overlay v-if="isLoading" opacity="0.5" color="#E3F2FD">
      <img src="../../assets/spinner.gif" alt="">
    </v-overlay>
    <v-row class="w-full m-0" style="margin: 0" v-if="isLoading == false">
      <v-col class="region-class" md="12">
        <v-row>
          <v-col id="regionSection" cols="12" class="pb-6">
            <v-row class="align-center">
              <div class="map-icon">
                <v-icon size="25" class="mdi mdi-map-outline"></v-icon>
              </div>
              <span class="text-h6 font-weight-bold ml-2"> Search By Region </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-0" v-for="(region, i) in regionWiseData" :key="i">
            <v-card elevation="4" class="rounded-lg">
              <v-card-title class="pt-1 pb-0">
                <span class="font-weight-bold"> {{ region.region }} </span>
                <v-spacer></v-spacer>
                <span class="font-weight-bold text-subtitle-2 d-flex">
                  Average Price in Region: 1.1Cr
                  <div class="percent-icon-class ml-3">
                    <v-icon size="18" color="orange" class="mdi mdi-percent ml-0"></v-icon>
                  </div>
                </span>
              </v-card-title>
              <v-card-text class="pb-0"
                :class="itemsToShow == 1 || (itemsToShow != 1 && isViewMore == true && ViewMoreSection == 1) ? 'px-1' : 'px-2'">
                <v-col v-if="isViewMore == true && ViewMoreSection == 1" cols="12"
                  class="view-more-home-page py-0 px-0">
                  <v-row class="mx-0">
                    <v-col v-for="(n, index) in region.products" :key="index" class="px-2 pb-1" cols="12" xl="3" lg="3">
                      <a :href="`/projects/${encryptIt(n.id)}`">
                        <div class="category-list" style="width: 100%;">
                          <img class="category-img"
                            :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                            width="100%" height="245" />
                          <div class="overlay-title">
                            <v-row class="align-center">
                              <v-col cols="12" class="py-0">
                                <h3>{{ n.name || "" }}</h3>
                                <h5>By {{ n.builder != null ? n.builder.name : "" || "" }}</h5>
                              </v-col>
                              <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                <span class="ml-2 text-caption"
                                  :class="isViewMore == false && itemsToShow != 1 ? 'text-class' : ''">
                                  <v-icon size="18" color="white" class="mdi  mdi-map-marker ml-0"></v-icon>
                                  {{ n.address }}
                                </span>
                              </v-col>
                              <v-col v-if="itemsToShow != 1" cols="6" xl="6" lg="6" md="6">
                                <span class="white py-2 px-3 font-weight-bold rounded-lg black--text"
                                  style="font-size: 13px;">
                                  {{ n.starting_price }} - {{ n.ending_price }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else cols="12" class="home-page py-0 px-2">
                  <carousel :perPage="itemsToShow" :navigationEnabled="itemsToShow == 1 ? false : true"
                    :paginationEnabled="false">
                    <vue-carousel-slide v-for="(n, index) in region.products" :key="index">
                      <v-col class="px-0 pb-1" cols="3" xl="3" lg="3">
                        <a :href="`/projects/${encryptIt(n.id)}`">
                          <div class="category-list">
                            <img class="category-img"
                              :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                              :width="$vuetify.breakpoint.smAndDown ? 200 : ($vuetify.breakpoint.lg ? 290 : 320)"
                              height="245" />
                            <div class="overlay-title">
                              <v-row class="align-center">
                                <v-col cols="12" class="py-0">
                                  <h3>{{ n.name || "" }}</h3>
                                  <h5>By {{ n.builder != null ? n.builder.name : "" || "" }}</h5>
                                </v-col>
                                <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                  <span class="ml-2 text-caption text-class">
                                    <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                    {{ n.address }}
                                  </span>
                                </v-col>
                                <v-col v-if="itemsToShow != 1" cols="6" xl="6" lg="6" md="6">
                                  <span class="white py-2 px-3 font-weight-bold rounded-lg black--text"
                                    style="font-size: 12px;">
                                    {{ n.starting_price }} - {{ n.ending_price }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </a>
                      </v-col>
                    </vue-carousel-slide>
                  </carousel>
                </v-col>
              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 py-0 mt-3">
              <v-row class="align-center mx-0">
                <v-col cols="8" xl="11" lg="10" class="px-0">
                  <hr />
                </v-col>
                <v-col cols="4" xl="1" lg="2">
                  <v-btn class="px-0" id="regionLaunchBtn" transparent elevation="0" color="#fff6e7" @click=viewMore(1)>
                    <span style="font-size: 15px"> View More</span>
                    <v-icon size="20" color="black" class="mdi mdi-arrow-top-right-bold-box-outline ml-0"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" class="pb-6" v-if="featuresDevelopers.length != 0 && itemsToShow != 1">
            <v-row class="align-center">
              <div class="map-icon">
                <v-icon size="25" class="mdi mdi-map-outline"></v-icon>
              </div>
              <span class="text-h6 ml-2 font-weight-bold"> Features Developers </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-0" v-if="featuresDevelopers.length != 0 && itemsToShow != 1">
            <v-card elevation="6" class="rounded-lg">
              <v-card-text class="pb-0">
                <v-col cols="12" class="home-page feature-developers py-0">
                  <carousel :perPage="itemsToShow" :navigationEnabled="true" :paginationEnabled="false">
                    <vue-carousel-slide v-for="(n, index) in featuresDevelopers" :key="index">
                      <v-col class="px-0 pb-1 developers-image-div" cols="3" xl="10" lg="10">
                        <div style="width: 100%;border-top-right-radius: 8px; border-top-left-radius: 8px;" height="300"
                          class="">
                          <img class="category-img"
                            :src="n.logo_path != null ? mediaUrl + n.logo_path : require('../../assets/builder.png')"
                            width="100%" height="80" />
                        </div>
                        <div class="px-3 pt-1 pb-2 text-subtitle-1" cols="12"
                          style="background-color: #261903; color: orange;border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;">
                          {{ n.text }}
                        </div>
                      </v-col>
                    </vue-carousel-slide>
                  </carousel>
                </v-col>
              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 py-4 mt-3">
              <v-row class="align-center mx-0">
                <v-col cols="12" xl="12" class="px-0">
                  <hr />
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col id="trendingSection" class="pb-6" cols="12" v-if="TrendingProjects.length != 0">
            <v-row class="align-center">
              <div class="map-icon">
                <v-icon size="25" class="mdi mdi-trending-up"></v-icon>
              </div>
              <span class="text-h6 ml-2 font-weight-bold"> Trending Projects </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-0" v-if="TrendingProjects.length != 0">
            <v-card elevation="4" class="rounded-lg">
              <v-card-text class="py-0"
                :class="itemsToShow == 1 || (itemsToShow != 1 && isViewMore == true && ViewMoreSection == 3) ? 'px-0' : 'px-2'">
                <v-col v-if="isViewMore == true && ViewMoreSection == 3" cols="12" class="trending-project py-0">
                  <v-row class="mx-0 mt-1">
                    <v-col v-for="(n, index) in TrendingProjects" :key="index" class="pb-1"
                      :class="itemsToShow == 1 ? 'px-0' : 'px-2'" cols="12" xl="3" lg="3">
                      <a :href="`/projects/${encryptIt(n.id)}`">
                        <div class="category-list" style="width: 100%;">
                          <img class="category-img"
                            :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                            width="100%" :height="$vuetify.breakpoint.smAndDown ? 210 : 190" />
                          <div class="overlay-title">
                            <v-row class="align-center">
                              <v-col cols="12" class="py-0">
                                <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                              </v-col>
                              <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                <span class="ml-2 text-caption text-class">
                                  <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                  {{ n.address }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else cols="12" class="trending-project py-0">
                  <carousel :perPage="itemsToShow" :navigationEnabled="itemsToShow == 1 ? false : true"
                    :paginationEnabled="false">
                    <vue-carousel-slide v-for="(n, index) in TrendingProjects" :key="index">
                      <v-col class="px-0 pb-1" cols="3" xl="3" lg="3">
                        <a :href="`/projects/${encryptIt(n.id)}`">
                          <div class="category-list">
                            <img class="category-img"
                              :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                              :width="$vuetify.breakpoint.smAndDown ? 235 : 320"
                              :height="$vuetify.breakpoint.smAndDown ? 200 : 190" />
                            <div class="overlay-title">
                              <v-row class="align-center">
                                <v-col cols="12" class="py-0">
                                  <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                  <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                                </v-col>
                                <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                  <span class="ml-2 text-caption text-class">
                                    <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                    {{ n.address }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </a>
                      </v-col>
                    </vue-carousel-slide>
                  </carousel>
                </v-col>

              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 py-0 mt-3">
              <v-row class="align-center mx-0">
                <v-col cols="8" xl="11" lg="10" class="px-0">
                  <hr />
                </v-col>
                <v-col cols="4" xl="1" lg="2">
                  <v-btn class="px-0" id="TrendingLaunchBtn" transparent elevation="0" color="#fff6e7"
                    @click=viewMore(3)>
                    <span style="font-size: 15px"> View More</span>
                    <v-icon size="20" color="black" class="mdi mdi-arrow-top-right-bold-box-outline ml-0"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col id="recommendedSection" class="pb-6" cols="12" v-if="RecommendedProjects.length != 0">
            <v-row class="align-center">
              <div class="map-icon">
                <v-icon size="25" class="mdi mdi-domain"></v-icon>
              </div>
              <span class="text-h6 ml-2 font-weight-bold"> Recommended Projects </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-0" v-if="RecommendedProjects.length != 0">
            <v-card elevation="4" class="rounded-lg">
              <v-card-text class="py-0"
                :class="itemsToShow == 1 || (itemsToShow != 1 && isViewMore == true && ViewMoreSection == 4) ? 'px-0' : 'px-2'">
                <v-col v-if="isViewMore == true && ViewMoreSection == 4" cols="12" class="trending-project py-0">
                  <v-row class="mx-0 mt-1">
                    <v-col v-for="(n, index) in RecommendedProjects" :key="index" class="pb-1"
                      :class="itemsToShow == 1 ? 'px-0' : 'px-2'" cols="12" xl="3" lg="3">
                      <a :href="`/projects/${encryptIt(n.id)}`">
                        <div class="category-list" style="width:100%">
                          <img class="category-img"
                            :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                            width="100%" :height="$vuetify.breakpoint.smAndDown ? 210 : 190" />
                          <div class="overlay-title">
                            <v-row class="align-center">
                              <v-col cols="12" class="py-0">
                                <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                              </v-col>
                              <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                <span class="ml-2 text-caption text-class">
                                  <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                  {{ n.address }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else cols="12" class="trending-project py-0">
                  <carousel :perPage="itemsToShow" :navigationEnabled="itemsToShow == 1 ? false : true"
                    :paginationEnabled="false">
                    <vue-carousel-slide v-for="(n, index) in RecommendedProjects" :key="index">
                      <v-col class="px-0 pb-1" cols="3" xl="3" lg="3">
                        <a :href="`/projects/${encryptIt(n.id)}`">
                          <div class="category-list">
                            <img class="category-img"
                              :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                              :width="$vuetify.breakpoint.smAndDown ? 235 : 320"
                              :height="$vuetify.breakpoint.smAndDown ? 200 : 190" />
                            <div class="overlay-title">
                              <v-row class="align-center">
                                <v-col cols="12" class="py-0">
                                  <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                  <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                                </v-col>
                                <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                  <span class="ml-2 text-caption text-class">
                                    <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                    {{ n.address }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </a>
                      </v-col>
                    </vue-carousel-slide>
                  </carousel>
                </v-col>
              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 py-0 mt-3">
              <v-row class="align-center mx-0">
                <v-col cols="8" xl="11" lg="10" class="px-0">
                  <hr />
                </v-col>
                <v-col cols="4" xl="1" lg="2">
                  <v-btn id="recommendedLaunchBtn" class="px-0" transparent elevation="0" color="#fff6e7"
                    @click=viewMore(4)>
                    <span style="font-size: 15px"> View More</span>
                    <v-icon size="20" color="black" class="mdi mdi-arrow-top-right-bold-box-outline ml-0"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
          <v-col cols="12" class="pb-6" id="preLaunchSection" v-if="PreLaunchProjects.length != 0">
            <v-row class="align-center">
              <div class="map-icon">
                <v-icon size="25" class="mdi mdi-domain"></v-icon>
              </div>
              <span class="text-h6 ml-2 font-weight-bold"> Pre Launch Projects </span>
            </v-row>
          </v-col>
          <v-col cols="12" class="px-0 py-0" v-if="PreLaunchProjects.length != 0">
            <v-card elevation="4" class="rounded-lg">
              <v-card-text class="py-0"
                :class="itemsToShow == 1 || (itemsToShow != 1 && isViewMore == true && ViewMoreSection == 5) ? 'px-0' : 'px-2'">
                <v-col v-if="isViewMore == true && ViewMoreSection == 5" cols="12" class="trending-project py-0"
                  :class="$vuetify.breakpoint.smAndDown && isViewMore == true ? 'px-0' : ''">
                  <v-row class="mx-0 mt-1">
                    <v-col class="px-2 pb-1" v-for="(n, index) in PreLaunchProjects" :key="index" cols="12" xl="3"
                      lg="3">
                      <a :href="`/projects/${encryptIt(n.id)}`">
                        <div class="category-list" style="width: 100%;">
                          <img class="category-img"
                            :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                            width="100%" :height="$vuetify.breakpoint.smAndDown ? 210 : 190" />
                          <div class="overlay-title">
                            <v-row class="align-center">
                              <v-col cols="12" class="py-0">
                                <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                              </v-col>
                              <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                <span class="ml-2 text-caption text-class">
                                  <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                  {{ n.address }}
                                </span>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </a>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else cols="12" class="trending-project py-0">
                  <carousel :perPage="itemsToShow" :navigationEnabled="itemsToShow == 1 ? false : true"
                    :paginationEnabled="false">
                    <vue-carousel-slide v-for="(n, index) in PreLaunchProjects" :key="index">
                      <v-col class="px-0 pb-1" cols="3" xl="3" lg="3">
                        <a :href="`/projects/${encryptIt(n.id)}`">
                          <div class="category-list">
                            <img class="category-img"
                              :src="n.feature_imagepath != null ? mediaUrl + n.feature_imagepath : require('../../assets/construction.jpg')"
                              :width="$vuetify.breakpoint.smAndDown ? 235 : 320"
                              :height="$vuetify.breakpoint.smAndDown ? 200 : 190" />
                            <div class="overlay-title">
                              <v-row class="align-center">
                                <v-col cols="12" class="py-0">
                                  <h3 style="color: orange;">{{ n.name || "" }}</h3>
                                  <!-- <h5>By {{ n.builder != null ? n.builder.name : "" }}</h5> -->
                                </v-col>
                                <v-col cols="12" xl="6" lg="6" md="6" class="py-0">
                                  <span class="ml-2 text-caption text-class">
                                    <v-icon size="18" color="white" class="mdi mdi-map-marker ml-0"></v-icon>
                                    {{ n.address }}
                                  </span>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </a>
                      </v-col>
                    </vue-carousel-slide>
                  </carousel>
                </v-col>
              </v-card-text>
            </v-card>
            <v-col cols="12" class="px-0 py-0 mt-3">
              <v-row class="align-center mx-0">
                <v-col cols="8" xl="11" lg="10" class="px-0">
                  <hr />
                </v-col>
                <v-col cols="4" xl="1" lg="2">
                  <v-btn class="px-0" id="preLaunchBtn" transparent elevation="0" color="#fff6e7" @click=viewMore(5)>
                    <span style="font-size: 15px"> View More</span>
                    <v-icon size="20" color="black" class="mdi mdi-arrow-top-right-bold-box-outline ml-0"></v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Carousel, Slide as VueCarouselSlide } from "vue-carousel";
import axios from "axios";
export default {
  name: "LAndingPAge",
  data() {
    return {
      search: "",
      cards: [
        {
          name: "Kharghar",
          building: 10,
        },
        {
          name: "Ulve",
          building: 15,
        },
      ],
      form: {

      },
      amenities: [],
      properties: [],
      regionItems: [],
      builderItems: [],
      regionWiseData: [],
      featuresDevelopers: [],
      TrendingProjects: [],
      RecommendedProjects: [],
      PreLaunchProjects: [],
      sizes: [],
      ReSellingData: [],
      currentText: 0,
      isViewMore: false,
      isLoading: false,
      isMounted: false,
    };
  },
  components: {
    Carousel,
    VueCarouselSlide,
  },
  computed: {
    itemsToShow() {
      if (window.innerWidth >= 1600) {
        return 5;
      } else if (window.innerWidth < 720) {
        return 1;
      } else if (window.innerWidth < 1080) {
        return 3;
      } else {
        return 4;
      }
    },
  },
  watch: {
    // Watch for changes in the route, specifically the hash part
    '$route.hash'(newHash) {
      if (newHash) {
        this.navigateToSection(newHash);
      }
    }
  },
  async mounted() {
    await this.getmasters();
    this.getData();
    this.isMounted = true;
  },
  methods: {
    async getmasters() {
      try {
        // Fetch master data from the server
        const response = await axios.get("/projects/masters", {
          headers: {
            "company-id": 1,
          },
        });
        const masters = response.data;
        //  region
        masters.region.forEach((region) => {
          this.regionItems.push({
            id: region.id,
            text: region.name,
            value: region.id,
          });
        });
        //  builders
        masters.builder.forEach((build) => {
          this.builderItems.push({
            id: build.id,
            text: build.name,
            is_feature_developer: build.is_feature_developer,
            logo_path: build.logo_path,
            value: build.id,
          });
        });
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    },
    async getData() {
      // let mobileView = this.itemsToShow == 1 ? true : false;
      this.isLoading = true;
      let ViewMore = this.isViewMore;
      let response = await axios.get(`/projects?is_view_more=${ViewMore}`, {
        headers: {
          "company-id": 1,
        },
      });
      this.form = response.data.data;
      if (this.isMounted == true || this.ViewMoreSection == 1) {
        this.regionWiseData = [];
        this.regionItems.forEach((e) => {
          const products = this.form.filter(product => product.region_id == e.id);
          if (products.length > 0) {
            this.regionWiseData.push({
              region: e.text,
              products: products
            })
          }
        })
        if (this.ViewMoreSection == 1) {
          this.navigateToSection('#regionSection');
        }
        this.ReSellingData = this.regionWiseData;
      }
      if (this.isMounted == true || this.ViewMoreSection == 2) {
        this.featuresDevelopers = [];
        this.builderItems.forEach((e) => {
          if (e.is_feature_developer == 1) {
            this.featuresDevelopers.push(e);
          }
        });
      }
      if (this.isMounted == true || this.ViewMoreSection == 4) {
        this.RecommendedProjects = [];
        this.form.forEach((e) => {
          if (e.is_recommended_projects == 1) {
            this.RecommendedProjects.push(e);
          }
        });
        if (this.ViewMoreSection == 4) {
          this.navigateToSection('#recommendedSection');
        }
      }
      if (this.isMounted == true || this.ViewMoreSection == 5) {
        this.PreLaunchProjects = [];
        this.form.forEach((e) => {
          if (e.status == 0) {
            this.PreLaunchProjects.push(e);
          }
        });
        if (this.ViewMoreSection == 5) {
          this.navigateToSection('#preLaunchSection');
        }
      }
      this.isMounted = false;
      if (this.$route.hash != null && this.isViewMore == false) {
        setTimeout(() => {
          this.navigateToSection(this.$route.hash);
        }, 100);
      }

      // if(this.$route.hash)
      this.isLoading = false;
    },
    navigateToSection(hash) {
      console.log('called', hash);

      const sectionName = hash.replace('#', '');

      // Find the section element
      let sectionElement = null;
      if (sectionName == 'regionSection') {
        sectionElement = document.getElementById('regionSection');
      } else if (sectionName == 'preLaunchSection') {
        sectionElement = document.getElementById('preLaunchSection');
        console.log('sectionElement', sectionElement);
      } else if (sectionName == 'trendingSection') {
        sectionElement = document.getElementById('trendingSection');
      } else if (sectionName == 'recommendedSection') {
        sectionElement = document.getElementById('recommendedSection');
      }
      // Scroll to the section
      if (sectionElement) {
        console.log('in');

        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async viewMore(ViewMoreSection) {
      this.isViewMore = true;
      this.ViewMoreSection = ViewMoreSection;
      await this.getData();
    },
    startTextCycle() {
      setInterval(() => {
        this.currentText = (this.currentText + 1) % 4;
        console.log(this.currentText);
      }, 2000);
    },
  }
};
</script>

<style scoped>
.text-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 125px;
}

.feature-developers .developers-image-div {
  background: #fff6e7;
}

.region-class .hooper {
  height: 330px;
}

.v-application ul,
.v-application ol {
  padding-left: 8px !important;
}

.hooper .hooper-slide {
  width: 251px !important;
}

.recommended-form .VueCarousel .VueCarousel-slide {
  padding: 2px;
}

.home-page .hooper .col-xl-3 {
  flex: 0 0 21.6% !important;
}

img {
  vertical-align: bottom;
}

.category-list,
.category-details {
  position: relative;
}

.category-list {
  position: relative;
  display: inline-block;
}

.category-list:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: inline-block;
  background: linear-gradient(to bottom,
      rgb(0 0 0 / 0%) 71%,
      rgb(0 0 0 / 77%) 83%);
}

.category-img {
  display: block;
  border-radius: 10px;
}

.overlay-title {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
  z-index: 999;
  color: white;
  width: 100%;
  line-height: 1.4;
}

.overlay-title h3 {
  font-size: 15px;
  font-weight: 700;
}

.overlay-title h5 {
  font-size: 12px;
  font-weight: 300;
}

.map-marker-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  height: 22px;
  width: 22px;
  padding: 5px;
}

.percent-icon-class {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: black;
  height: 22px;
  width: 22px;
  padding: 5px;
}
</style>
<style>
.horizontal-line {
  width: 80%;
  height: 2px;
  background-color: black;
  /* Sets the color of the line */
  border: none;
  /* Removes any default border */
  margin: 0 auto;
  /* Centers the line horizontally */
}

.text-title {
  font-size: 18px;
}

.location-chip {
  background-color: #fff2e6;
  color: #6d4c41;
  margin-right: 4px;
  padding: 0 8px;
  font-size: 14px;
}

.vertical-line {
  width: 2px;
  height: 20px;
  background-color: orange;
}

.property-info {
  color: #9e9e9e;
  font-size: 14px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid orange;
  border-radius: 25px;
  padding: 5px;
  background-color: white;
  gap: 5px;
}

.enquiry {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 20px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: rgb(249, 218, 159);
  color: orange;
  border: none;
}

.enquirybyagent {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 20px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.enquirybybuilder {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  font-size: 20px;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: rgb(248, 180, 53);
  background-color: transparent;
  color: black;
  border: 1px solid black;
}

.highlight {
  color: orange;
}

.title-text {
  white-space: nowrap;
}

.average {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.subtitle {
  font-size: 14px;
  color: black;
  font-weight: bold;
  margin-right: 8px;
}

.v-icon {
  margin-left: 8px;
}

.no-wrap-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
}

.text-orange {
  color: orange;
}

.appicon {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.card-content {
  position: relative;
}

.card-containertwo {
  height: 100%;
}

.currency-containertwo {
  position: absolute;
  top: 24px;
  left: -3px;
  color: orange;
}

.text-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
}

.left-section {
  display: flex;
  align-items: center;
  border: 1px solid orange;
  border-radius: 10px;
}

.right-section {
  display: flex;
  align-items: center;
}

.left-section .dropdown {
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  /* background-color: #fff4db; */
  color: black;
  /* font-weight: bold; */
}

.right-section {
  gap: 15px;
}

.brand {
  font-size: 20px;
  font-weight: bold;
  color: #f7a500;
}

.search-bar {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: #fff4db;
  padding: 5px 10px;
  border: 1px solid #f7a500;
}

.search-bar input {
  border: none;
  background: transparent;
  outline: none;
  padding: 5px;
}

.search-bar button {
  background: none;
  border: none;
  cursor: pointer;
  color: #f7a500;
}

.profile-icon {
  padding: 8px;
  border-radius: 50%;
  background-color: #f7a500;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.project {
  color: #f7a500;
}

.sqft {
  color: black;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .home-page .VueCarousel .VueCarousel-inner {
    max-width: 65% !important;
  }

  .horizontal.timeline .steps .step {
    padding: 5px !important;
  }

  .horizontal.timeline .steps .step:last-child span {
    left: 4px !important;
  }

  .horizontal.timeline .steps .step span {
    font-size: 11px;
    left: 18px !important;
  }

  .floorplan-class .VueCarousel .VueCarousel-inner {
    max-width: 67% !important;
  }

  .trending-project .VueCarousel .VueCarousel-inner {
    max-width: 75% !important;
  }

  .app-bar {
    padding: 10px 15px;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  .brand {
    font-size: 18px;
  }

  .right-section {
    gap: 10px;
  }

  .search-bar {
    display: none;
  }

  .menu-icon {
    font-size: 28px;
  }

  .left-section {
    display: none;
  }

  .icon-container {
    display: none;
  }

  .icon-containertwo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 2px solid orange;
    border-radius: 25px;
    padding: 3px;
    background-color: white;
    gap: 5px;
  }
}

@media (min-width: 1600px) {
  .view-more-home-page .col-xl-3 {
    max-width: 19% !important;
  }

  .trending-project .row .col-xl-3 {
    max-width: 20% !important;
  }

  .amenities-class .col-xl-3 {
    max-width: 24% !important;
    margin: 4px;
  }
}

@media (min-width: 1024px) {
  .icon-containertwo {
    display: none;
  }

  .image-section {
    margin-left: 120px;
  }

  .amenities-class .col-lg-3 {
    max-width: 24% !important;
    margin: 2px;
  }

  .appicon {
    display: none;
  }

  .category-list {
    margin-right: 10px;
  }
}

.seller {
  display: flex;
  align-items: center;
  gap: 15px;
}

.map-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: orange;
  height: 40px;
  width: 40px;
  padding: 5px;
}

.map-icon .mdi {
  font-size: 36px;
  color: black;
  padding-right: 7px;
}

.custom-card {
  gap: 10px;
  border: 2px solid orange;
  border-radius: 10px;
  background: white;
  padding: 10px;
  height: 100%;
}

.map-icon-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: orange;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.map-icon-card>i {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: white;
  padding-right: 10px;
}

.v-col {
  padding: 0;
}

.v-card-text {
  margin: 0;
  padding: 0;
  height: 100%;
}

.text-center>h2 {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.flat>h2 {
  font-size: 24px;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}

.enquiry-form-heading {
  display: flex;
  gap: 15px;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .amenities-class .col-4 {
    max-width: 32.333333% !important;
    margin: 1px;
  }

  .text-center>h2 {
    font-size: 24px;
    text-align: center;
  }

  .flat>h2 {
    font-size: 24px;
    text-align: center;
  }

  .enquiry-form-heading {
    align-items: center;
  }
}
</style>
