<template>
  <v-container fill-height>
    <v-row align="center" justify="center" dense>
      <v-col class="py-12">
        <v-card
          elevation="6"
          class="mx-auto"
          variant="outlined"
          color="grey lighten-3"
          rounded="xxl"
          :height="$vuetify.breakpoint.mdAndDown ? '40pc' : '600px'"
        >
          <v-card-text>
            <v-row>
              <v-col md="6" sm="6" lg="6" align-self="center">
                <v-img
                  :src="
                    is_company_user
                      ? `${mediaUrl + logo_path}`
                      : require('../../assets/sapp-logo.png')
                  "
                  alt="PROJECTSQFT"
                  class="mx-auto"
                  width="200"
                ></v-img>
              </v-col>
              <v-col align-self="center" md="6" sm="6" lg="6" class="py-auto">
                <v-card
                  color="px-12 grey lighten-4"
                  rounded="xl"
                  :height="$vuetify.breakpoint.mdAndDown ? '22pc' : '565px'"
                >
                  <section v-if="current_screen == 1">
                    <v-card-title class="text-h4 mb-1 font-weight-bold"
                      >Forgot Password</v-card-title
                    >
                    <v-card-subtitle class="font-weight-bold"
                      >Don’t worry, we will help you recover your
                      account.</v-card-subtitle
                    >
                    <v-card-text>
                      <v-text-field
                        v-model="form.email"
                        outlined
                        solo
                        rounded
                        label="Email"
                        placeholder="Enter email address"
                        :error-messages="errors.email"
                      >
                        <v-btn
                          x-small
                          @click="isOtpSendFunction()"
                          slot="append"
                          color="blue"
                          text
                          v-show="!isOtpSend"
                        >
                          Send Code
                        </v-btn>
                        <v-btn
                          x-small
                          slot="append"
                          color="blue"
                          text
                          v-show="isOtpSend"
                          :disabled="isOtpSend"
                        >
                          Re-send ({{ countdown }})
                        </v-btn>
                      </v-text-field>
                      <v-text-field
                        outlined
                        rounded
                        solo
                        v-model="form.otp"
                        :error-messages="errors.otp"
                        name="input-10-1"
                        label="Verfication code *"
                        placeholder="Enter verfication code"
                        messages="* Required fields"
                      ></v-text-field>
                      <v-btn
                        large
                        rounded
                        color="primary"
                        block
                        :loading="isLoading"
                        :disabled="isLoading"
                        @click="checkVerfication()"
                      >
                        Reset Password
                      </v-btn>
                      <v-btn small text color="red" to="/login"
                        >Back to Login</v-btn
                      >
                    </v-card-text>
                  </section>
                  <!-- Reset Password  -->
                  <section v-if="current_screen == 2">
                    <v-card-title><h2>Reset Password</h2> </v-card-title>
                    <v-card-text align="center">
                      <v-text-field
                        outlined
                        rounded
                        solo
                        v-model="form.password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'"
                        :error-messages="errors.password"
                        name="input-10-1"
                        label="Password *"
                        placeholder="New password"
                        counter
                        @click:append="show_password = !show_password"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        rounded
                        solo
                        v-model="form.soft_password"
                        :append-icon="
                          show_soft_password ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="show_soft_password ? 'text' : 'password'"
                        :error-messages="errors.soft_password"
                        label="Confirm New Password *"
                        placeholder="Confirm New password"
                        counter
                        @click:append="show_soft_password = !show_soft_password"
                      ></v-text-field>
                      <span class="text-caption primary--text"
                        >Password should consist of minimum 8 characters and
                        should have at least 1 Uppercase character (A-Z), 1
                        lowercase character (a-z), 1 digit (0-9) and 1 special
                        character (*&$@#!+-)</span
                      >
                      <v-btn
                        large
                        color="primary"
                        rounded
                        block
                        :loading="isLoading"
                        :disabled="isLoading"
                        @click="resetPassword()"
                        >Submit
                      </v-btn>
                      <v-btn small text color="red">Back to Login</v-btn>
                    </v-card-text>
                  </section>
                  <!-- Successful Message -->
                  <section v-if="current_screen == 3">
                    <v-card-title>
                      <v-row>
                        <v-col align="center">
                          <v-icon size="80" color="green"
                            >mdi-check-circle</v-icon
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col align="center">
                          <h1 style="word-break: keep-all">
                            Your password has been <br />
                            changed successfully.
                          </h1>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-card-text align="center">
                      <span class="text-subtitle-1 my-10"
                        >Please log in to your account using the updated
                        password. !</span
                      >
                      <v-btn
                        large
                        color="primary"
                        rounded
                        to="/login"
                        block
                        :loading="isLoading"
                        :disabled="isLoading"
                      >
                        Login
                      </v-btn>
                    </v-card-text>
                  </section>
                </v-card>
                <!-- <span>{{ errorMsg }}</span> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: "",
        soft_password: "",
        portal: true,
      },
      current_screen: 1,
      loggedInRole: "",
      logo_path: "../../assets/sapp-logo.png",
      is_company_user: false,
      showPassword: false,
      isLoading: false,
    };
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      let companyId = localStorage.getItem("company-id");
      this.getCompany(companyId);
    }
  },
  computed: {
    passwordMatchMessage() {
      if (
        this.formEmailTab.password.length > 0 &&
        this.formEmailTab.soft_password.length > 0
      ) {
        return this.formEmailTab.password === this.formEmailTab.soft_password
          ? "Passwords match."
          : "Passwords do not match.";
      } else {
        return "";
      }
    },
  },
  methods: {
    async getCompany(companyId) {
      let company = await axios.get(`/companies/${companyId}`);
      company = company.data.data;
      this.logo_path = company.logo_path;
      this.is_company_user = true;
    },
    async isOtpSendFunction() {
      this.isLoading = true;
      try {
        await axios.post(`/forgot_password`, this.form);
        this.isOtpSend = true;
        let timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
            this.isOtpSend = false;
            this.countdown = 60; // Reset countdown to 60 seconds
          }
        }, 1000);

        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async send_otp() {
      this.isOtpSend = true;
      await axios.post(`forgot_password`, this.form);
      let timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
          this.isOtpSend = false;
          this.countdown = 60; // Reset countdown to 60 seconds
        }
      }, 1000);
    },
    isOTPSendDisabled() {
      let response = true;
      if (this.form.email) {
        response = false;
        if (this.is_otp_sent == true) {
          response = true;
        }
      }
      return response;
    },
    async checkVerfication() {
      this.isLoading = true;
      try {
        let response = await axios.post(`/forgot_password`, this.form);
        this.isLoading = false;
        if (response.status == 200) {
          this.current_screen = 2;
        }
      } catch (error) {
        console.log("error", error);
        this.isLoading = false;
      }
    },
    async resetPassword() {
      this.isLoading = true;
      try {
        let response = await axios.post(`/forgot_password`, this.form);
        this.isLoading = false;
        if (response.status == 200) {
          this.current_screen = 3;
        }
      } catch (error) {
        console.log("error", error);
        this.isLoading = false;
      }
    },
  },
};
</script>
