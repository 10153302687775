<template>
  <v-main> </v-main>
</template>
<script>
export default {
  name: "HomePage",
  data() {
    return {
      isLoading: false,
      roleId: "",
      counts: {},
      attendace_count: [],
      startDate: "",
      endDate: "",
      userAttendances: [],
      current_date: "",
      page: 1,
      serialNoStarting: 1,
      paginationLength: 0,
      count: 0,
      search_keyword: "",
      designations: [
        {
          id: 0,
          text: "Select Designation",
          value: "",
        },
      ],
      designationId: "",
      selectedDateRange: "daily",
    };
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Afacad:wght@400;500&display=swap");

.chart_data {
  display: flex;
}

#chart,
#chart1 {
  background: white;
}

.card-button2 {
  padding: 7px 15px !important;
  background: #3354f4;
  color: white;
  border-radius: 10px;
  font-size: 14px;
}

.page-content {
  width: 100%;
}

.search-field {
  border: 1px solid #dfe2e5;
  border-radius: 10px;
}

.search-field2 {
  border: 1px solid #dfe2e5;
  border-radius: 10px;
}

.text-left {
  color: #c1c1c5 !important;
}

.chart-radio {
  position: absolute;
  top: 20px;
  right: 32px;
}

.chart-radio input {
  margin-left: 10px;
  margin-right: 3px;
}

.chart-radio label {
  color: #6c718e !important;
  font-family: "Afacad", sans-serif;
}
</style>
