<template>
  <div>

    <v-app>
      <div class="app-bar">
        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense>
            <v-list-item-group v-model="group" active-class="orange--text text--accent-4">
              <v-list-item @click="updateSaction('preLaunchSection')">
                <v-list-item-icon>
                  <v-icon>mdi-launch</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">Pre - Launch Projects</v-list-item-title>
              </v-list-item>

              <v-list-item @click="updateSaction('trendingSection')">
                <v-list-item-icon>
                  <v-icon>mdi-trending-up</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">Trending Projects</v-list-item-title>
              </v-list-item>
              <v-list-item @click="updateSaction('regionSection')">
                <v-list-item-icon>
                  <v-icon>mdi-home-search-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">Search By Region</v-list-item-title>
              </v-list-item>
              <v-list-item @click="updateSaction('listSection')">
                <v-list-item-icon>
                  <v-icon>mdi-office-building-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">List Property</v-list-item-title>
              </v-list-item>
              <v-list-item to="/properties-ad">
                <v-list-item-icon>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">Post Property</v-list-item-title>
              </v-list-item>
              <v-list-item @click="ContactFormDialog = true">
                <v-list-item-icon>
                  <v-icon>mdi-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-subtitle-2">Contact Us</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <div style="
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
        ">
          <div class="left-section">
            <div class="dropdown" @click="updateSaction('preLaunchSection')">Pre - Launch Projects</div>
            <div class="dropdown" @click="updateSaction('trendingSection')">Trending Projects</div>
            <div class="dropdown" @click="updateSaction('regionSection')">Search By Region</div>
            <div class="dropdown" @click="updateSaction('listSection')">List Property</div>
            <a class="dropdown" style="text-decoration: none;" href="/properties-ad">Post Property</a>
            <a class="dropdown" style="text-decoration: none;" @click="ContactFormDialog = true">ContactUs</a>
          </div>
          <div>
            <a class="brand" href="/projects" style="text-decoration: none;">
              <span class="project">Project</span>
              <span class="sqft">SQFT</span>
            </a>
          </div>
        </div>

        <div class="right-section">
          <div class="search-bar">
            <div>
              <v-icon class="mdi mdi-magnify">mdi-magnify</v-icon>
            </div>
            <input type="text" placeholder="Search For Projects" />
            <button><i class="fa fa-search"></i></button>
          </div>
        </div>
        <div v-if="itemsToShow == 1" style="border: 1px solid orange" class="rounded-xl">
          <v-icon class="mdi mdi-account-circle">mdi-account</v-icon>
          <v-app-bar-nav-icon color="orange" @click="drawer = true">
          </v-app-bar-nav-icon>
        </div>
        <v-dialog v-model="ContactFormDialog" max-width="600">
          <v-card>
            <v-col class="recommended-form">
              <v-row>
                <v-col md="12">
                  <div class="enquiry-form-heading">
                    <div class="map-icon">
                      <v-icon class="mdi mdi-headset" size="25"></v-icon>
                    </div>
                    <span class="text-h6 mt-1 font-weight-bold">Contact Us Form</span>
                  </div>
                </v-col>
              </v-row>
              <v-card class="mt-2">
                <v-col cols="12" class="py-0">
                  <span class="font-weight-bold text-subtitle-1">Please Enter Your Details Here</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="contactform.name" placeholder="Name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="contactform.email" placeholder="Email - ID" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field v-model="contactform.number" placeholder="phone" outlined dense></v-text-field>
                </v-col>
              </v-card>
              <v-card-actions>
                <v-btn style="background-color: green; color: white; width: 100%" :loading="isLoading">
                  Save
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-card>
        </v-dialog>
      </div>
      <div>
        <router-view />
      </div>

    </v-app>
  </div>

</template>

<script type="text/javascript">
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      ContactFormDialog: false,
      contactform: {},
      menuOpen: false,
      group: null,
    };
  },
  computed: {
    itemsToShow() {
      if (window.innerWidth >= 1600) {
        return 5;
      } else if (window.innerWidth < 720) {
        return 1;
      } else if (window.innerWidth < 1080) {
        return 3;
      } else {
        return 4;
      }
    },
  },
  methods: {
    updateSaction(section) {
      this.drawer = false;
      this.$router.push({ path: '/projects', hash: `#${section}` });
    },
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
    },
  },
};
</script>
<style>
.v-navigation-drawer {
  z-index: 9999;
}
</style>
<!-- <style scoped>
.navbar {
  height: 50px;
  background-color: Lightgrey;
  display: flex;
  border-radius: 0px;
}

.logo1 {
  color: red;
}

.logo2 {
  color: grey;
}

.logo,
.hamburger-wrap {
  width: 100px;
  height: 100%;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  align-items: center;
}

.hamburger-wrap {
  float: right;
  justify-content: flex-end;
}

.hamburger {
  width: 45px;
  height: 45px;
  background-color: black;
  border-radius: 4px;
}

.hamburger:focus {
  outline: none;
}

.hamburger__line,
.hamburger__middle {
  display: block;
  width: 30px;
  height: 2px;
  border-radius: 2px;
  background-color: #FFFFFF;
  margin-top: 7px;
  margin-bottom: 7px;
}

.hamburger__middle {
  width: 20px;
  margin-left: 10px;
}

.dropdown {
  margin-top: -20px;
  height: 0px;
  background-color: lightgreen;
  transition: height 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dropdown-after {
  height: calc(100vh - 50px);
  transition: height 0.2s ease;
}

.navlist {
  list-style: none;
}

.navlistitem {
  text-transform: uppercase;
  text-align: center;
  padding: 20px;
}

.navlistitem a {
  color: #FFFFFF;
}
</style> -->