<template>
  <v-container fill-height>
    <v-row align="center" justify="center" dense>
      <v-col class="py-12">
        <v-card
          elevation="6"
          class="mx-auto"
          variant="outlined"
          color="grey lighten-3"
          rounded="xxl"
          :height="$vuetify.breakpoint.mdAndDown ? '40pc' : '600px'"
        >
          <v-card-text>
            <v-row>
              <v-col md="6" sm="6" lg="6" align-self="center">
                <v-img
                  :src="
                    is_company_user
                      ? `${mediaUrl + logo_path}`
                      : require('../../assets/sapp-logo.png')
                  "
                  alt="PROJECTSQFT"
                  class="mx-auto"
                  width="200"
                ></v-img>
              </v-col>
              <v-col align-self="center" md="6" sm="6" lg="6" class="py-auto">
                <v-card
                  color="px-12 grey lighten-4"
                  rounded="xl"
                  :height="$vuetify.breakpoint.mdAndDown ? '22pc' : '565px'"
                >
                  <v-card-title class="text-h4 mb-1 font-weight-bold"
                    >Welcome{{ is_company_user ? " back" : "" }}</v-card-title
                  >
                  <v-card-subtitle class="font-weight-bold"
                    >Welcome{{ is_company_user ? " back" : "" }}, Please enter
                    your details.</v-card-subtitle
                  >
                  <v-card-text>
                    <v-text-field
                      v-model="form.email"
                      outlined
                      solo
                      rounded
                      label="Email"
                      placeholder="Enter email address"
                      clearable
                      @click:clear="form.email = null"
                      prepend-inner-icon="mdi-email"
                      :error-messages="errors.email"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      outlined
                      solo
                      rounded
                      @keypress.enter="login"
                      :error-messages="errors.password"
                      label="Password"
                      placeholder="Enter password"
                      prepend-inner-icon="mdi-lock"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                    <v-btn
                      :loading="isLoading"
                      :disabled="isLoading"
                      color="primary"
                      block
                      rounded
                      @click="login"
                    >
                      Login
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      align="right"
                      to="/forgot-password"
                    >
                      Forgot password?
                    </v-btn>
                  </v-card-text>
                </v-card>
                <!-- <span>{{ errorMsg }}</span> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  name: "LoginPage",
  data() {
    return {
      form: {
        email: "",
        password: "",
        portal: true,
      },
      loggedInRole: "",
      logo_path: "../../assets/sapp-logo.png",
      is_company_user: false,
      showPassword: false,
      isLoading: false,
    };
  },
  mounted() {
    if (localStorage.getItem("company-id").length) {
      let companyId = localStorage.getItem("company-id");
      this.getCompany(companyId);
      console.log(companyId);
    }
  },
  methods: {
    async getCompany(companyId) {
      await axios
        .get(`/get_company/${this.encryptIt(companyId)}`)
        .then((company) => {
          company = company.data.data;
          this.logo_path = company.logo_path;
          this.is_company_user = true;
        })
        .catch((error) => {
          this.logo_path = "../../assets/sapp-logo.png";
          console.error("Error fetching data:", error);
        });
    },
    ...mapActions({
      logIn: "auth/logIn",
    }),
    async login() {
      this.isLoading = true;
      console.log(this.isLoading);
      this.logIn(this.form)
        .then(() => {
          this.isLoading = false;
          location.reload("/");
        })
        .catch(() => {
          alert("User can't login in portal");
          this.isLoading = false;
        });
      this.isLoading = false;
      console.log(this.isLoading);
    },
  },
};
</script>
