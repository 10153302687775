<template>
  <v-main>
    <v-container>
      <v-col class="recommended-form" md="4">
        <v-row>
          <v-col md="12">
            <div class="enquiry-form-heading">
              <div class="map-icon">
                <v-icon class="mdi mdi-headset" size="25"></v-icon>
              </div>
              <span class="text-h6 mt-1 font-weight-bold">Enquiry Form</span>
            </div>
          </v-col>
        </v-row>
        <v-card>
          <v-col class="recommended-form">
            <v-col cols="12" class="py-0">
              <span class="font-weight-bold text-subtitle-1">Please Enter Your Details Here</span>
            </v-col>
            <v-col cols="12" class="pt-4 pb-0">
              <span class="font-weight-bold text-subtitle-1">What are you intereseted in?</span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-chip-group v-model="form.bhk_size" column active-class="primary--text">
                <v-chip filter outlined value="1 BHK">
                  1 BHK
                </v-chip>
                <v-chip filter outlined value="2 BHK">
                  2 BHK
                </v-chip>
                <v-chip filter outlined value="3 BHK">
                  3 BHK
                </v-chip>
                <v-chip filter outlined value="4 BHK">
                  4 BHK
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" class="pt-1 pb-0">
              <span class="font-weight-bold text-subtitle-1">Available At?</span>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-chip-group v-model="form.available_at" column active-class="primary--text">
                <v-chip filter outlined value="Morning">
                  Morning
                </v-chip>
                <v-chip filter outlined value="Afternoon">
                  Afternoon
                </v-chip>
                <v-chip filter outlined value="Evening">
                  Evening
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field v-model="form.name" placeholder="Name" outlined dense
                :error-messages="errors.name"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field v-model="form.email" placeholder="Email - ID" outlined dense
                :error-messages="errors.email"></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field v-model="form.number" placeholder="phone" outlined dense
                :error-messages="errors.number"></v-text-field>
            </v-col>
          </v-col>
        </v-card>
        <v-card-actions>
          <v-btn @click="save" style="background-color: green; color: white; width: 100%">
            Enquire
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios";
export default {
  name: "EnquiryCreatePage",
  data() {
    return {
      valid: false,
      form: {
        bhk_size: '1 BHK',
        available_at: 'Morning'
      },
      isLoading: false,
    };
  },
  mounted() {
    if (this.$route.query.project_id == null) {
      this.$router.back()
    }
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;
        this.form.project_id = this.$route.query.project_id;
        await axios.post("/enquire_forms", this.form);
        localStorage.setItem(`'project_submitted_${this.form.project_id}'`, this.form.project_id);
        await this.saveUserTimestamp(null, this.form);
        this.isLoading = false;
        this.$router.back()
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
    },
    async saveUserTimestamp(userId, formData) {
      console.log("User timestamp saved:", userId, formData);
    },
  },
};
</script>

<style scoped>
.enquire-form-container {
  background-color: #fff3e0;
  padding: 20px;
  border-radius: 10px;
}

.selectable-option {
  padding: 12px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
  /* Transparent by default */
  color: black;
}

.selected-owner {
  background-color: rgb(248, 180, 53);
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-builder {
  background-color: orange;
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-agent {
  background-color: orange;
  /* Orange background for Owner */
  color: white;
  /* White text for contrast */
}

.selected-option {
  background-color: transparent;
  /* Transparent background for Agent and Builder */
  color: black;
  /* Text color */
}


.selected-new-property {
  background-color: rgb(236,
      172,
      53);
  /* Primary color background for New Property */
  color: white;
  /* White text for contrast */
}

.selected-old-property {
  background-color: rgb(236,
      172,
      53);
  /* Transparent background for Old Property */
  color: black;
  /* Text color */
}


.selected-flat {
  background-color: rgb(241, 184, 78);
  /* Primary color background for selected flat */
  color: white;
  /* White text for contrast */
}
</style>
