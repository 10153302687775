<template>
  <div style="height: 200px; background: #fee2b5">
    <v-row style="margin: 0px !important">
      <v-col cols="6">
        <v-col cols="12">
          <span class="text-h6" style="text-decoration: underline">
            Site Map
          </span>
        </v-col>
        <v-col cols="12">
          <span class="text-h6" style="text-decoration: underline">
            Trending Projects
          </span>
        </v-col>
        <v-col cols="12">
          <span class="text-h6" style="text-decoration: underline">
            Pre - Launch Projects
          </span>
        </v-col>
        <v-col cols="12">
          <span
            class="text-h6"
            style="text-decoration: underline"
            @click="ContactFormDialog = true"
          >
            Contact Us
          </span>
        </v-col>
      </v-col>
      <!-- <v-col cols="1" class="d-flex align-center" style="height: 200px;display: flex; align-items: center;">
                <div class="mt-10" style="border-left:1px solid ; height: 100px;">

                </div>
            </v-col> -->
      <v-col cols="6" style="border-left: 1px solid">
        <v-col cols="12">
          <a
            href="/terms-and-conditions"
            class="text-h6"
            style="text-decoration: underline; color: black"
          >
            Terms and conditions
          </a>
        </v-col>
        <v-col cols=" 12">
          <a
            href="/privacy-policies"
            class="text-h6"
            style="text-decoration: underline; color: black"
          >
            Privacy Policies
          </a>
        </v-col>
        <v-col cols="12">
          <a
            href="/refund-policies"
            class="text-h6"
            style="text-decoration: underline; color: black"
          >
            Refund Policies
          </a>
        </v-col>
        <v-col cols="12">
          <a
            href="/about-us"
            class="text-h6"
            style="text-decoration: underline; color: black"
          >
            About Us
          </a>
        </v-col>
      </v-col>
      <v-dialog v-model="ContactFormDialog" max-width="600">
        <v-card>
          <v-col class="recommended-form">
            <v-row>
              <v-col md="12">
                <div class="enquiry-form-heading">
                  <div class="map-icon">
                    <v-icon class="mdi mdi-headset" size="25"></v-icon>
                  </div>
                  <span class="text-h6 mt-1 font-weight-bold"
                    >Contact Us Form</span
                  >
                </div>
              </v-col>
            </v-row>
            <v-card class="mt-2">
              <!-- Form -->
              <form @submit.prevent="sendEmail">
                <!-- Header -->
                <v-col cols="12" class="py-0">
                  <span class="font-weight-bold text-subtitle-1"
                    >Please Enter Your Details Here</span
                  >
                </v-col>

                <!-- Name Input -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="contactform.name"
                    placeholder="Name"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Email Input -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="contactform.email"
                    placeholder="Email - ID"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Phone Number Input -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    v-model="contactform.phone"
                    placeholder="Phone"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <!-- Submit Button -->
                <v-card-actions>
                  <v-btn
                    type="submit"
                    style="background-color: green; color: white; width: 100%"
                    :loading="isLoading"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card>
          </v-col>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script type="text/javascript">
import axios from "axios";
export default {
  data() {
    return {
      ContactFormDialog: false,
      isLoading: false,
      contactform: {
        name: "",
        email: "",
        phone: "",
      },
    };
  },
  methods: {
    updateContact() {
      this.ContactFormDialog = true;
      this.$emit("open-contact-form");
    },
    sendEmail() {
      this.isLoading = true;

      axios
        .post("contacts", this.contactform)
        .then((response) => {
          this.isLoading = false;
          this.ContactFormDialog = false;

          this.contactform = {
            name: "",
            email: "",
            phone: "",
          };

          console.log("Form submitted successfully:", response.data);
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error submitting the form:", error);
        });
    },
  },
};
</script>